import React from 'react';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

const Home = ({ data }) => {
	const allServices = data.allPrismicServices.edges;
	return (
		<Layout bodyClass="page-home">
			<SEO title={data.prismicHomepage.data.meta_title.text} />
			<Helmet>
				<meta
					name="description"
					content={data.prismicHomepage.data.meta_description.text}
				/>
				<link rel="canonical" href={`${data.site.siteMetadata.siteUrl}en/`} />
				<link
					rel="alternate"
					href={`${data.site.siteMetadata.siteUrl}en/`}
					hreflang="en"
				/>
				<link
					rel="alternate"
					href={`${data.site.siteMetadata.siteUrl}sv/`}
					hreflang="sv-se"
				/>
			</Helmet>
			<div className="home-hero-wrapper">
				<div className="intro pb-4">
					<div className="container">
						<h1>{data.prismicHomepage.data.headline.text}</h1>
						<p>{data.prismicHomepage.data.tagline.text}</p>
					</div>
				</div>
				<div className="container pt-2">
					<Call
						phoneText={data.prismicHomepage.data.contact_phone_text.text}
						emailText={data.prismicHomepage.data.contact_email_text.text}
						buttonText={data.prismicHomepage.data.contact_button_text.text}
						buttonUrl={data.prismicHomepage.data.contact_url.text}
						button
					/>
				</div>
			</div>

			<div className="container pt-8 pt-md-8">
				<h3 className="title-3 text-dark mb-4 forceHeadline">
					{data.prismicHomepage.data.view_services_title.text}
				</h3>
				<div className="row justify-content-start">
					{allServices.map(edge => (
						<div
							key={edge.node.data.service_name.text}
							className="col-12 col-md-4 mb-1"
						>
							<div className="card service service-teaser">
								<div className="card-content">
									<Link to={`en/services/${edge.node.uid}`}>
										<h2>
											{edge.node.data.service_name.text}{' '}
											<span className="click">→</span>
										</h2>
									</Link>
									<p>{edge.node.data.service_intro.text}</p>
								</div>
							</div>
						</div>
					))}
				</div>
				<div className="col-12">
					<h3 className="title-3 text-dark mb-4 mt-6 forceHeadline">
						{data.prismicHomepage.data.intro_title.text}
					</h3>
					<div
						dangerouslySetInnerHTML={{
							__html: data.prismicHomepage.data.intro_text.html
						}}
					/>
				</div>
			</div>

			<div className="container pt-5 pb-5 pt-md-7 pb-md-7">
				{/* just to add some spacing between services and the footer */}
			</div>
		</Layout>
	);
};

export const query = graphql`
	query {
		site {
			siteMetadata {
				siteUrl
			}
		}
		prismicHomepage(lang: { eq: "en-gb" }) {
			data {
				headline {
					text
				}
				tagline {
					text
				}
				intro_title {
					text
				}
				intro_text {
					html
				}
				meta_title {
					text
				}
				meta_description {
					text
				}
				view_services_title {
					text
				}
				contact_button_text {
					text
				}
				contact_phone_text {
					text
				}
				contact_email_text {
					text
				}
				contact_url {
					text
				}
			}
		}
		allPrismicServices(filter: { lang: { eq: "en-gb" } }) {
			edges {
				node {
					id
					uid
					data {
						service_name {
							text
						}
						service_intro {
							text
						}
					}
				}
			}
		}
	}
`;

export default Home;
